.tile {
    /*width: 62px;
    height: 66px;*/
    position: absolute;
    transition: all 0.1s ease-in-out;
    background-color:rgb(87, 71, 81);
    opacity: 1;
}

.tileglow {
    /*width: 62px;
    height: 66px;*/
    display: flex;
    z-index: 1;
    position: absolute;
    transition: all 0.1s ease-in-out;
    border-radius: 2px;
    /*background-color:rgb(224, 215, 255);*/
    animation: glow 3s forwards;
    color: rgb(250, 229, 255);
}

@keyframes glow {
    0% {
        box-shadow: 0 0 10px 5px;
        
    }
    15%, 25% {
        box-shadow: 0 0 10px 10px;
        
    }
    100% {
        box-shadow: 0 0 10px -10px;
        opacity: 0;
    }
}
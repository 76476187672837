.wavy {
    animation-name: wavy;
    animation-duration: 1.3s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    position: relative;
    top: 0;
    left: 0;
  }
@keyframes wavy {
    0% {
        top: 0px;
    }
    50% {
        top: -15px;
    }
    100% {
        top: 0px;
    }
}
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: #802c83;
}
.big-white-text{
    font-family: "Arial Black", Gadget, sans-serif;
    color: #fff;
    font-size:30px;
    line-height:60px;
}

.css-button-3d--green {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-right:150px;
    margin-left:150px;
    height: 40px;
    position: relative;
    
    cursor: pointer;
    border-radius: 10px;
    background: #80ed99;
    box-shadow: 0 8px #57cc99;
    width: 60px;
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
}
.css-button-3d--green:hover {
    box-shadow: 0 5px #57cc99;
    top: 3px;
}
.css-button-3d--green:active {
    box-shadow: 0 0px #57cc99;
    top: 8px;
    transition: all .1s ease;
}

.center {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
  }

.buttonposition {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
}

.centerimage {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 2;
    pointer-events: none;
}

.circley {
    /*width: 500px;
    height: 500px;
    background-color: green;
    border-radius: 50%;*/

    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #555;
    color: rgb(58, 58, 58);
    box-shadow: 0 8px #000000;
    min-width: 1em;
    border-radius: 50%;
    vertical-align: middle;
    width: 150px;
    height: 150px;
    opacity: 0.4;
}
.luna_wawa {
  align-items: center;
  text-align: center;
  font-family: "Arial Black", Gadget, sans-serif;
  font-size:40px;
  line-height: 10px;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15);
  color: #FFFFFF;
}
  
.BaseBunny{
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  pointer-events: none;
}

.BunnyCoin{
  background-image: url(/static/media/BunnyCoin.85d74711.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: -15px;
  align-content: center;
  justify-content: center;
  text-align: center;
  z-index: 1;
  pointer-events: none;
  height: 115px;
  width: 130px;
  margin-right: 1em;
}

.BuyButton{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
  padding: 0px;
  margin: 0px;
}

.BuyButton:hover {
  top: 2px;
}
.BuyButton:active {
  transition: all .1s ease;
}

.luna_wawa_small {
  align-items: center;
  text-align: center;
  font-family: "Arial Black", Gadget, sans-serif;
  font-size:25px;
  line-height: 10px;
  color: #FFFFFF;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  /*text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15);
  color: #FFFFFF;*/
}

.jigsawButton_back{
  background-image: url(/static/media/jigsawButton_back.276e5aff.png);
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  transition: 0.2s ease;
  transform: rotate(0)  scale(1) ;
  width: 100px;
  height: 100px;
}
.jigsawButton_back:hover{
  transform: rotate(-5deg)  scale(1.2) ;
}

.jigsawButton_front{
  display: flex;
  background-image: url(/static/media/jigsawButton_front.d8b086c0.png);
  background-size: cover;
  background-position: center;
  width: 100px;
  height: 100px;
  transform-origin: center;
  transition: 0.2s ease;
  transform: rotate(0)  scale(1);
  cursor: pointer;
}

.jigsawButton_front:hover{
  transform: rotate(15deg)  scale(1.2);
}

.buyRow{
  position: absolute;
  display:flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  margin: auto;
  width: 25em;
  height: 120px;
  top: 500px;
  max-width: 100%
}
@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
.loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
  
.spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 350px;
  }
.wavy {
    animation-name: wavy;
    animation-duration: 1.3s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    position: relative;
    top: 0;
    left: 0;
  }
@keyframes wavy {
    0% {
        top: 0px;
    }
    50% {
        top: -15px;
    }
    100% {
        top: 0px;
    }
}
.customizeButton_back{
    background-image: url(/static/media/customizeButton_back.d3b5bef8.png);
    background-size: cover;
    background-repeat:no-repeat;
    width: 80px;
    height: 80px;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-left: 1em;
    transition: 0.2s ease;
    transform: rotate(0)  scale(1);
  }
  .customizeButton_back:hover{
    transform: rotate(-5deg)  scale(1.2) ;
  }
  
  .customizeButton_front{
    display: flex;
    background-image: url(/static/media/customizeButton_front.f2f4361c.png);
    background-size: contain;
    width: 80px;
    height: 80px;
    transform-origin: center;
    transition: 0.2s ease;
    transform: rotate(0)  scale(1);
    cursor: pointer;
  }
  
  .customizeButton_front:hover{
    transform: rotate(10deg)  scale(1.2);
  }

  .pink {
    display:flex;
    flex-direction: row;
    align-content: center;
    width: 300px;
    height: 80px;
}

.pinkShard {
    background-image: url(/static/media/pinkShard.2ec64d3d.png);
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
    display:block;
    width: 100%;
    height: 100%;
}

.pinkPiece {
    background-image: url(/static/media/pinkPiece.64e714ef.png);
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    width: 100%;
    height: 100%;
    top: 50%;
}

.big-white-text{
    font-family: "Arial Black", Gadget, sans-serif;
    color: #fff;
    font-size:30px;
    text-align: center;
}

.alignPinkText{
    position: relative;
}

.topRow{
    display: flex;
    align-items: bottom;
    justify-content: center;
}

.boardRow{
    display:flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
    scroll-snap-type: x;
    width: 100%;
}

.boardContained{
  align-content: center;
  padding: 1em;
  justify-content: center;
  align-items: center;
  scroll-snap-align: center;
}

.tinyText {
  align-items: center;
  text-align: center;
  font-family: "Arial Black", Gadget, sans-serif;
  font-size:15px;
  line-height: 10px;
  color: #FFFFFF;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  padding: .5em;
  /*text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15);
  color: #FFFFFF;*/
}
.board {
    width: 330px;
    height: 347px;
    position: relative;
    background: rgb(77, 11, 68);
    display:flex;
    margin-left:auto;
    margin-right:auto;
}

.overlayFinished{
    background: rgb(77, 11, 68);
    display:block;
    margin: auto;
    left:0;
    right:0;
}

.overlayFinishedGlow{
    z-index: 2;
    transition: all 0.1s ease-in-out;
    border-radius: 2px;
    animation: glowFinished 5s forwards;
    color: rgb(250, 229, 255);
}

@keyframes glowFinished {
    0% {
        box-shadow: 0 0 15px 10px;
        
    }
    20%, 25% {
        box-shadow: 0 0 20px 20px;
        opacity: 1;
        
    }
    100% {
        box-shadow: 0 0 15px -15px;
        opacity: 0;
    }
}

.frame{
    border-width: 4mm;
    border-style:ridge;
    box-shadow: 0px 0px .4em 0em rgb(0, 0, 0);
}

.jigsawNameText {
    align-items: center;
    text-align: center;
    font-family: "Arial Black", Gadget, sans-serif;
    font-size:25px;
    line-height: 10px;
    color: #FFFFFF;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    
    /*text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15);
    color: #FFFFFF;*/
  }
.tile {
    /*width: 62px;
    height: 66px;*/
    position: absolute;
    transition: all 0.1s ease-in-out;
    background-color:rgb(87, 71, 81);
    opacity: 1;
}

.tileglow {
    /*width: 62px;
    height: 66px;*/
    display: flex;
    z-index: 1;
    position: absolute;
    transition: all 0.1s ease-in-out;
    border-radius: 2px;
    /*background-color:rgb(224, 215, 255);*/
    animation: glow 3s forwards;
    color: rgb(250, 229, 255);
}

@keyframes glow {
    0% {
        box-shadow: 0 0 10px 5px;
        
    }
    15%, 25% {
        box-shadow: 0 0 10px 10px;
        
    }
    100% {
        box-shadow: 0 0 10px -10px;
        opacity: 0;
    }
}
::selection {
    color: black;
    background: #FFAA00;
}

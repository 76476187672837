.luna_wawa {
  align-items: center;
  text-align: center;
  font-family: "Arial Black", Gadget, sans-serif;
  font-size:40px;
  line-height: 10px;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15);
  color: #FFFFFF;
}
  
.BaseBunny{
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  pointer-events: none;
}

.BunnyCoin{
  background-image: url('../images/bunnycustomization/BunnyCoin.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: -15px;
  align-content: center;
  justify-content: center;
  text-align: center;
  z-index: 1;
  pointer-events: none;
  height: 115px;
  width: 130px;
  margin-right: 1em;
}

.BuyButton{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
  padding: 0px;
  margin: 0px;
}

.BuyButton:hover {
  top: 2px;
}
.BuyButton:active {
  transition: all .1s ease;
}

.luna_wawa_small {
  align-items: center;
  text-align: center;
  font-family: "Arial Black", Gadget, sans-serif;
  font-size:25px;
  line-height: 10px;
  color: #FFFFFF;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  /*text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15);
  color: #FFFFFF;*/
}

.jigsawButton_back{
  background-image: url('../images/bunnycustomization/jigsawButton_back.png');
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  transition: 0.2s ease;
  transform: rotate(0)  scale(1) ;
  width: 100px;
  height: 100px;
}
.jigsawButton_back:hover{
  transform: rotate(-5deg)  scale(1.2) ;
}

.jigsawButton_front{
  display: flex;
  background-image: url('../images/bunnycustomization/jigsawButton_front.png');
  background-size: cover;
  background-position: center;
  width: 100px;
  height: 100px;
  transform-origin: center;
  transition: 0.2s ease;
  transform: rotate(0)  scale(1);
  cursor: pointer;
}

.jigsawButton_front:hover{
  transform: rotate(15deg)  scale(1.2);
}

.buyRow{
  position: absolute;
  display:flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  margin: auto;
  width: 25em;
  height: 120px;
  top: 500px;
  max-width: 100%
}
.board {
    width: 330px;
    height: 347px;
    position: relative;
    background: rgb(77, 11, 68);
    display:flex;
    margin-left:auto;
    margin-right:auto;
}

.overlayFinished{
    background: rgb(77, 11, 68);
    display:block;
    margin: auto;
    left:0;
    right:0;
}

.overlayFinishedGlow{
    z-index: 2;
    transition: all 0.1s ease-in-out;
    border-radius: 2px;
    animation: glowFinished 5s forwards;
    color: rgb(250, 229, 255);
}

@keyframes glowFinished {
    0% {
        box-shadow: 0 0 15px 10px;
        
    }
    20%, 25% {
        box-shadow: 0 0 20px 20px;
        opacity: 1;
        
    }
    100% {
        box-shadow: 0 0 15px -15px;
        opacity: 0;
    }
}

.frame{
    border-width: 4mm;
    border-style:ridge;
    box-shadow: 0px 0px .4em 0em rgb(0, 0, 0);
}

.jigsawNameText {
    align-items: center;
    text-align: center;
    font-family: "Arial Black", Gadget, sans-serif;
    font-size:25px;
    line-height: 10px;
    color: #FFFFFF;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    
    /*text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15);
    color: #FFFFFF;*/
  }
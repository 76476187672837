.big-white-text{
    font-family: "Arial Black", Gadget, sans-serif;
    color: #fff;
    font-size:30px;
    line-height:60px;
}

.css-button-3d--green {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-right:150px;
    margin-left:150px;
    height: 40px;
    position: relative;
    
    cursor: pointer;
    border-radius: 10px;
    background: #80ed99;
    box-shadow: 0 8px #57cc99;
    width: 60px;
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
}
.css-button-3d--green:hover {
    box-shadow: 0 5px #57cc99;
    top: 3px;
}
.css-button-3d--green:active {
    box-shadow: 0 0px #57cc99;
    top: 8px;
    transition: all .1s ease;
}

.center {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
  }

.buttonposition {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
}

.centerimage {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 2;
    pointer-events: none;
}

.circley {
    /*width: 500px;
    height: 500px;
    background-color: green;
    border-radius: 50%;*/

    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #555;
    color: rgb(58, 58, 58);
    box-shadow: 0 8px #000000;
    min-width: 1em;
    border-radius: 50%;
    vertical-align: middle;
    width: 150px;
    height: 150px;
    opacity: 0.4;
}
.customizeButton_back{
    background-image: url('../images/jigsaw/customizeButton_back.png');
    background-size: cover;
    background-repeat:no-repeat;
    width: 80px;
    height: 80px;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-left: 1em;
    transition: 0.2s ease;
    transform: rotate(0)  scale(1);
  }
  .customizeButton_back:hover{
    transform: rotate(-5deg)  scale(1.2) ;
  }
  
  .customizeButton_front{
    display: flex;
    background-image: url('../images/jigsaw/customizeButton_front.png');
    background-size: contain;
    width: 80px;
    height: 80px;
    transform-origin: center;
    transition: 0.2s ease;
    transform: rotate(0)  scale(1);
    cursor: pointer;
  }
  
  .customizeButton_front:hover{
    transform: rotate(10deg)  scale(1.2);
  }

  .pink {
    display:flex;
    flex-direction: row;
    align-content: center;
    width: 300px;
    height: 80px;
}

.pinkShard {
    background-image: url("../images/jigsaw/pinkShard.png");
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
    display:block;
    width: 100%;
    height: 100%;
}

.pinkPiece {
    background-image: url("../images/jigsaw/pinkPiece.png");
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    width: 100%;
    height: 100%;
    top: 50%;
}

.big-white-text{
    font-family: "Arial Black", Gadget, sans-serif;
    color: #fff;
    font-size:30px;
    text-align: center;
}

.alignPinkText{
    position: relative;
}

.topRow{
    display: flex;
    align-items: bottom;
    justify-content: center;
}

.boardRow{
    display:flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
    scroll-snap-type: x;
    width: 100%;
}

.boardContained{
  align-content: center;
  padding: 1em;
  justify-content: center;
  align-items: center;
  scroll-snap-align: center;
}

.tinyText {
  align-items: center;
  text-align: center;
  font-family: "Arial Black", Gadget, sans-serif;
  font-size:15px;
  line-height: 10px;
  color: #FFFFFF;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  padding: .5em;
  /*text-shadow: 0 1px 0 #CCCCCC, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15);
  color: #FFFFFF;*/
}